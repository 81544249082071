import React from "react";
import { graphql } from "gatsby";
import TagPage from "../components/TagPage";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  filterUniqueTags,
} from "../lib/helpers";

export const query = graphql`
  query TagPageTemplateQuery($id: String!) {
    page: sanityBlogPostTag(id: { eq: $id }) {
      tagName
      slug {
        current
      }
      id
    }
    posts: allSanityPost(filter: { tags: { elemMatch: { id: { eq: $id } } } }) {
      edges {
        node {
          id
          title
          publishedAt(formatString: "MMM DD, YYYY")
          slug {
            current
          }
          mainImage {
            asset {
              fluid(maxWidth: 768) {
                ...GatsbySanityImageFluid
              }
            }
          }
          authors {
            author {
              id
              name
              image {
                asset {
                  fixed(width: 36) {
                    ...GatsbySanityImageFixed_noBase64
                  }
                }
              }
              slug {
                current
              }
            }
          }
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
          _rawBody(resolveReferences: { maxDepth: 5 })
          tags {
            tagName
            slug {
              current
            }
          }
        }
      }
    }
    allSanitySiteSettings {
      nodes {
        contactInfo {
          email
          facebook
          instagram
          twitter
        }
        description
        title
      }
    }
    allSanityAuthor {
      nodes {
        image {
          asset {
            fixed(width: 40) {
              ...GatsbySanityImageFixed
            }
          }
        }
        id
        name
        slug {
          current
        }
      }
    }
  }
`;

const TagPageTemplate = (props) => {
  const { data, location } = props;
  const page = data && data.page;

  const { allSanitySiteSettings, allSanityAuthor } = data;

  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  const tags = filterUniqueTags(postNodes);

  return (
    <Layout location={location.pathname}>
      {page && (
        <SEO
          title={page.tagName || "Untitled"}
          description={`All of Inside Cal Poly's posts about ${page.tagName}`}
        />
      )}
      {page && (
        <TagPage
          {...page}
          posts={postNodes}
          sidebarInfo={{ allSanitySiteSettings, allSanityAuthor, tags }}
        />
      )}
    </Layout>
  );
};

export default TagPageTemplate;
