import React from "react";
import { MaxWidth, PageTitle, PageDivider, Col66, Col33, FlexRow } from "./ui-components";
import styled from "styled-components";
import SingleRowVertical from "./post-previews/SingleRowVertical";
import Sidebar from "./Sidebar";
import { Link } from "gatsby";

const TagPageTitle = styled(PageTitle)`
  @media only screen and (max-width: 480px) {
    text-align: left;
  }
`;

const PreHeading = styled.p`
  color: rgba(0, 0, 0, 0.54);

  margin-bottom: 0;
  line-height: 1.5;
  font-size: 15px;
  @media only screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

const EmptyPostsContent = styled.div`
  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    border-bottom: 2px solid rgb(73, 126, 107);
    padding: 1px 2px 0;
    -webkit-transition: background-color 0.2s linear, color 0.2s linear, border 0.2s linear;
    transition: background-color 0.2s linear, color 0.2s linear, border 0.2s linear;
    color: inherit;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      outline-width: 0;
      color: #000;
      background-color: rgba(73, 126, 107, 0.5);
      border-bottom: 2px solid rgba(73, 126, 107, 0.5) !important;
    }
  }
`;

export default function TagPage(props) {
  const { tagName } = props;
  const posts = props.posts;

  const TaggedPosts =
    posts.length !== 0 ? (
      <SingleRowVertical
        posts={posts}
        authorTop={true}
        readMoreLink={true}
        className={"border-bottom"}
      />
    ) : null;

  const NoPosts = (
    <EmptyPostsContent>
      <p>
        It looks like there are no stories with this tag. Please view the{" "}
        <Link to={"/archive/"}>archive</Link> to browse all of the stories from Inside Cal Poly.
      </p>
    </EmptyPostsContent>
  );

  return (
    <>
      <MaxWidth>
        <PreHeading>Tagged in</PreHeading>
        <TagPageTitle>{tagName}</TagPageTitle>
      </MaxWidth>
      <PageDivider />
      <MaxWidth>
        <FlexRow>
          <Col66>{props.posts.length > 0 ? TaggedPosts : NoPosts}</Col66>
          <Col33>
            <Sidebar
              settings={props.sidebarInfo.allSanitySiteSettings.nodes[0]}
              tags={props.sidebarInfo.tags}
              authors={props.sidebarInfo.allSanityAuthor.nodes}
            />
          </Col33>
        </FlexRow>
      </MaxWidth>
    </>
  );
}
